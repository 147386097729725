<template>
  <div>
    <!-- CONTENT -->
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" md="7" lg="5" class="text-center">
        <template v-if="solde != undefined">
          <v-card outlined>
            <v-card-text>
              <p>
                <span class="light-blue--text"
                  >{{ first_name }} {{ last_name }}</span
                >, votre solde au bar est de
              </p>
              <p>
                <span class="text-h1">{{ solde }}€</span>
              </p>
              <p v-if="last_update">
                <i>Dernière mise à jour: {{ last_update }}</i>
              </p>
            </v-card-text>
          </v-card>
        </template>
        <template v-else-if="errored">
          <v-card outlined>
            <v-card-text class="red--text" style="white-space: pre-wrap">
              {{ error || "Une erreur est survenue !" }}
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const o_date_short_fr = new Intl.DateTimeFormat("fr", {
  dateStyle: "short",
  timeStyle: "medium",
});

export default {
  name: "HomePage",
  data() {
    return {
      first_name: undefined,
      last_name: undefined,
      solde: undefined,
      last_update: undefined,
      // errors
      error: undefined,
      errored: false,
      not_found: false,
    };
  },
  mounted() {
    this.axiosPost(process.env.VUE_APP_API_URL + "/solde")
      .then((res) => {
        this.first_name = res.data.first_name;
        this.last_name = res.data.last_name;
        this.solde = res.data.solde;
        if (res.data.last_update) {
          this.last_update = o_date_short_fr.format(
            new Date(res.data.last_update)
          );
        }
      })
      .catch((err) => {
        this.error = err.response.data.error;
        this.errored = true;
      });
  },
};
</script>